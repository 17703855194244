/**
 * Save to clipboard buttons
 */
(() => {
	const buttons = document.querySelectorAll<HTMLElement>('[data-copy]');

	const copyToClipboard = (text: string) => {
		if (navigator?.clipboard?.writeText) {
			return navigator.clipboard.writeText(text);
		}
		return Promise.reject('Not able to copy text');
	};

	buttons.forEach((button) => {
		const buttonText = button.querySelector<HTMLElement>('[data-copy-label]');

		button.addEventListener('click', () => {
			copyToClipboard(window.location.href);
			if (buttonText) {
				buttonText.innerText = 'Kopierat';
			}
			button.classList.add('button--copied');
		});
	});
})();

/**
 * Add a class to the header when the page has scrolled from the top.
 */
((doc) => {
	const margin = 10;
	const hasScrolledDown = () => window.scrollY > margin;

	let scrolledDown: boolean | undefined;

	const update = () => {
		let newScrolledDown = hasScrolledDown();

		if (scrolledDown !== newScrolledDown) {
			doc.classList.toggle('s-scrolled-down', newScrolledDown);
			scrolledDown = newScrolledDown;
		}
	};

	window.addEventListener('scroll', update, { passive: true });

	update();
})(document.documentElement);
